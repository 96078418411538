import React, { FC } from "react";
import { BadgeType } from './types';
import { BadgeIcon } from './badgeIcon';
import { Icon } from '../Icon/Icon';
import { classNames } from '../../utils/classNames';
import { BadgeFilterTitle } from './badgeFilterTitle';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type MultiBadgeFilterPropsType = {
    readonly title: string;
    readonly badges: BadgeType[];
    readonly selected: BadgeType[];
    readonly onSelect: (badge: BadgeType, selected: boolean) => void;
}

export const MultiBadgeFilter: FC<MultiBadgeFilterPropsType> = ({
    title, badges, selected, onSelect,
}) => (
    <div
        className="badge-filter"
    >
        <BadgeFilterTitle title={title} badge={badges[0]} selected={selected.length > 0} />
        <div className="multi-values">
            {badges.map((badge: BadgeType) => (
                <OverlayTrigger
                    flip
                    placement="top"
                    delay={{ show: 1000, hide: 100 }}
                    key={badge.type}
                    overlay={(
                        <Tooltip id="tooltip">
                            {badge.description}
                        </Tooltip>
                    )}
                >
                    <div
                        className={classNames('multi-value', ['selected', 'hover', selected.includes(badge)])}
                        onClick={() => onSelect(badge, !selected.includes(badge))}
                    >
                        {badge.shortLabel}
                    </div>
                </OverlayTrigger>
            ))}
        </div>
    </div>
);
