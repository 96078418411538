import { Col } from 'react-bootstrap';
import React, { FC } from 'react';

import { toHumanString } from '../common';

type VersionDownloadedPropsType = {
    readonly downloads?: number;
    readonly version?: string;
};
export const VersionDownloaded: FC<VersionDownloadedPropsType> = ({ downloads, version }) => (
    <Col lg={12} className="pb-3">
        <span className="text-secondary">Version: </span>
        <span className="fw-bold">{version}</span>
        {' '}
        {downloads ? <>was downloaded {toHumanString(downloads)} times in total.</> : <>has never been downloaded.</>}
    </Col>
);
