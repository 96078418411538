import React, { useState } from 'react';
import { RouteProps } from 'react-router';
import { Col, Row } from 'react-bootstrap';

import { ModuleList } from './list';
import { ModuleListFilter } from './filter';
import { PageHeader } from '../index';
import { ModuleTabType } from './types';
import { moduleDescription } from '../constants';
import { FAVORITE_MODULES_LIST_ID } from '../Favorite/useFavorite';
import { useSearchParams } from 'react-router-dom';

export type { ModuleTabType } from './types';

export const isOfTypeModuleTab = (key: string): key is ModuleTabType => {
    return ['readme', 'inputs', 'outputs', 'dependencies', 'resources', 'changelog', 'versions', 'forks'].includes(key);
};

const ModulesListPage: React.FC<RouteProps> = (props) => (
    <Row id="content">
        <Col md={4} lg={3} xxl={2} className="sidebar">
            <ModuleListFilter />
        </Col>
        <Col md={8} lg={9} xxl={10}>
            <PageHeader
                title="Modules"
            >
                {moduleDescription}
            </PageHeader>
            <ModuleList />
        </Col>
    </Row>
);

export default ModulesListPage;
