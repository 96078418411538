import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import ProviderList from './list';
import ProviderListFilter from './filter';
import { ProviderDocumentationResourceType } from './types';
import { providerDescription } from '../constants';
import PageHeader from '../pageHeader';
import { FAVORITE_PROVIDERS_LIST_ID } from '../Favorite/useFavorite';
import { useSearchParams } from 'react-router-dom';

export { default as ProviderPage } from './view';

export type {
    CategoryResourceType,
    TierResourceType,
    ProviderCardPropsType,
    ProviderState,
    DocCategory,
    DocSubcategory,
    ProviderDocumentationStateType,
    ProviderVersionItemType,
    ProviderDocumentationResourceType,
    SourceChangelogResourceType,
    OverviewPropsType,
    ProviderTabTypes
} from './types';

export const getCategoryName = (pd: ProviderDocumentationResourceType) => {
    if (pd.attributes.category === 'guides') {
        return pd.attributes.subcategory ?? pd.attributes.category;
    }
    return !pd.attributes.subcategory ? pd.attributes.category : pd.attributes.subcategory;
};

export const getSubcategoryName = (pd: ProviderDocumentationResourceType) => {
    if (pd.attributes.category === 'guides') {
        return '';
    }
    return !pd.attributes.subcategory ? '' : pd.attributes.category;
};

export const ProvidersListPage = () => (
    <Row id="content">
        <Col md={4} lg={3} xxl={2} className="sidebar">
            <ProviderListFilter />
        </Col>
        <Col md={8} lg={9} xxl={10}>
            <PageHeader
                title="Providers"
            >
                {providerDescription}
            </PageHeader>
            <ProviderList />
        </Col>
    </Row>
);


export default ProvidersListPage;
