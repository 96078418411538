import { Alert, Button, Col, Dropdown, Form, Image, Navbar, Row } from 'react-bootstrap';
import { FaCube } from 'react-icons/fa';
import { RxFileText } from 'react-icons/rx';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment/moment';
import { GoLink, GoRepoForked } from 'react-icons/go';
import React, { FC } from 'react';
import { ModuleAttributesType, ModuleItemType, ModuleVersionResponseDataType, SubmoduleDataType } from './types';
import Graph from '../Graph';
import { VersionDownloaded } from './versionDownloaded';
import { SubModulesMenu } from './subModulesMenu';
import { getMdDescription } from '../Provider/utils';
import { Favorite } from '../Favorite/favorite';
import { FAVORITE_MODULES_LIST_ID } from '../Favorite/useFavorite';
import { Icon } from '../Icon/Icon';
import { Badges } from '../Badges/badges';


type ModuleHeaderPropsType = {
    readonly moduleVersionData: ModuleVersionResponseDataType | null;
    readonly moduleId: number;
    readonly module: ModuleAttributesType;
    readonly selectedSubmoduleId: number | null;
    readonly name: string;
    readonly submoduleReadme: string | null;
    readonly moduleTitle: string;
    readonly submoduleIds: number[];
    readonly rootSubmoduleId: number | null;
    readonly moduleVersionId?: number;
    readonly exampleIds: number[];
    readonly potentialForkOf?: ModuleItemType;
    readonly sourceParticipationData?: number[];
    readonly forkedFrom?: string;
    readonly onSelectSubModuleHandler: (id: number | null) => void;
};

export const ModuleHeader: FC<ModuleHeaderPropsType> = ({
    moduleVersionData,
    moduleId,
    module,
    selectedSubmoduleId,
    name,
    submoduleReadme,
    moduleTitle,
    submoduleIds,
    rootSubmoduleId,
    moduleVersionId,
    exampleIds,
    potentialForkOf,
    sourceParticipationData,
    forkedFrom,
    onSelectSubModuleHandler,
}) => {
    const { namespace, provider, version } = useParams();

    const relationships = moduleVersionData ? moduleVersionData.included : null;

    const startPartOfUrl = `/modules/${encodeURIComponent(namespace ?? '')}/${encodeURIComponent(name ?? '')}/${encodeURIComponent(provider ?? '')}`;

    const canonicalUrl = window.location.href.replace(`${startPartOfUrl}/${version}`, `${startPartOfUrl}/latest`);

    if (selectedSubmoduleId === null) {
        return (
            <div>
                <Row className="p-0 m-0">
                    <Col lg={12}>
                        <Row className="rounded-pane-top bg-gradient-2">
                            <div>
                                <div className="text-secondary">
                                    <a href="/" className="breadcrumb-link">
                                        Home
                                    </a>{' '}
                                    /{' '}
                                    <a href="/modules" className="breadcrumb-link">
                                        Modules
                                    </a>
                                </div>
                                <Row>
                                    <Col>
                                        <h1>
                                            Module: {name}
                                        </h1>
                                    </Col>
                                    <Col className="col-auto d-flex flex-row-reverse align-items-center px-0">
                                        <Favorite
                                            itemId={moduleId}
                                            listId={FAVORITE_MODULES_LIST_ID}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col lg={12}>
                                        <Navbar expand="sm" className="my-2 justify-content-start">
                                            <Dropdown
                                                hidden={
                                                    submoduleIds.length === 0 ||
                                                    (submoduleIds.length === 1 &&
                                                        submoduleIds[0] === rootSubmoduleId)
                                                }
                                                className="pe-2"
                                            >
                                                <Dropdown.Toggle variant="primary" key={`submodules-selector-${moduleVersionId}`} size="sm">
                                                    <span>
                                                        <FaCube />
                                                        Submodules
                                                    </span>
                                                </Dropdown.Toggle>
                                                <SubModulesMenu
                                                    namespace={String(namespace)}
                                                    name={name}
                                                    version={String(version)}
                                                    provider={String(provider)}
                                                    moduleVersionId={moduleVersionId}
                                                    listIds={submoduleIds}
                                                    type="submodules"
                                                    submodules={relationships ? relationships.submodules : []}
                                                    onSelectSubModuleHandler={onSelectSubModuleHandler}
                                                />
                                            </Dropdown>
                                            <Dropdown hidden={exampleIds.length === 0}>
                                                <Dropdown.Toggle variant="primary" key={`examples-selector-${moduleVersionId}`} size="sm">
                                                    <span>
                                                        <RxFileText />
                                                        Examples
                                                    </span>
                                                </Dropdown.Toggle>
                                                <SubModulesMenu
                                                    namespace={String(namespace)}
                                                    name={name}
                                                    version={String(version)}
                                                    provider={String(provider)}
                                                    moduleVersionId={moduleVersionId}
                                                    listIds={exampleIds}
                                                    type="examples"
                                                    submodules={relationships ? relationships.submodules : []}
                                                    onSelectSubModuleHandler={onSelectSubModuleHandler}
                                                />
                                            </Dropdown>
                                        </Navbar>
                                    </Col>
                                </Row>
                            </div>
                        </Row>

                        <Row className="rounded-pane-bottom pane-shadow">
                            {!!potentialForkOf && (
                                <Col lg={12}>
                                    <Alert variant="danger">
                                        This module is a fork of the{' '}
                                        <Link to={`/modules/${potentialForkOf.attributes.fullName}/latest`} className="text-decoration-none fw-bold">
                                            {potentialForkOf.attributes.namespace}/{potentialForkOf.attributes.name}
                                        </Link>{' '}
                                        module!
                                    </Alert>
                                </Col>
                            )}
                            <Row className="pe-0">
                                <Col lg={9} md={9} sm={8}>
                                    <Row>
                                        <Col lg={12} className="pb-3">
                                            <span className="text-secondary">Provider:</span>
                                            <strong>{module.providerName}</strong>
                                        </Col>

                                        <Col lg={12} className="pb-3">
                                            <span className="text-secondary">Description: </span>
                                            {!!moduleVersionData?.data.attributes.description ? moduleVersionData?.data.attributes.description : 'n/a'}
                                            <Helmet>
                                                <meta
                                                    name="description"
                                                    content={moduleVersionData?.data.attributes.description || moduleTitle}
                                                />
                                                <link rel="canonical" href={canonicalUrl} />
                                            </Helmet>
                                        </Col>
                                        
                                        <Col lg={12} className="pb-3">
                                            <span className="text-secondary">Last published:</span>{' '}
                                            {moment(moduleVersionData?.data.attributes.publishedAt, 'YYYY-MM-DDThh:mm:ssZ').fromNow()} by{' '}
                                            <strong>{module.namespace}</strong>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={3} md={3} sm={4} className="pe-0">
                                    <Row>
                                        <Col lg={12} className="pb-3 text-sm-end">
                                            <span className="text-primary ps-sm-3" title="Weekly commits for the past 52 weeks">
                                                {sourceParticipationData &&
                                                  <Graph height="2.3em" rawData={sourceParticipationData} />}
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Col lg={12} hidden={module.ownerName === ''} className="pb-3">
                                <span className="text-secondary">Owner:</span>
                                <span className="fw-bold">{module.ownerName}</span>
                            </Col>

                            <Col lg={12} className="pb-3">
                                <span className="text-secondary">Source code:</span>{' '}
                                <a href={module.source} className="text-decoration-none">
                                    <span className="text-nowrap">
                                        <GoLink />
                                        {module.source.replace(/^[^\/]+\/\/[^\/]+\//, '')}
                                    </span>
                                </a>
                            </Col>

                            {forkedFrom && (
                                <Col lg={12} className="pb-3">
                                    <span className="text-secondary">Forked from:</span>{' '}
                                    <a href={forkedFrom} className="text-decoration-none">
                                        <GoRepoForked />
                                        {forkedFrom.replace(/^[^\/]+\/\/[^\/]+\//, '')}
                                    </a>
                                </Col>
                            )}

                            <VersionDownloaded
                                version={moduleVersionData?.data.attributes.version}
                                downloads={moduleVersionData?.data.attributes.downloads}
                            />

                            <Col lg={12}>
                                <span className="text-secondary">This module is available in</span>{' '}
                                {module.isInTofuRegistry && (
                                    <>
                                        <span className="px-1 fst-italic text-nowrap">
                                            <Icon id="opentofu" className="me-1 mb-1 platformIcon" />
                                            OpenTofu
                                        </span>
                                        {` and `}
                                    </>
                                )}

                                <span className="ps-1 fst-italic text-nowrap">
                                    <Icon id="terraform" className="me-1 mb-1 platformIcon" />
                                    Terraform
                                </span>
                                {' '}
                                <span className="text-secondary ps-1">
                                    {module.isInTofuRegistry ? 'registries.' : 'registry.'}
                                </span>
                            </Col>
                            <Col lg={12}>
                                <Badges badges={module.badges} subject="module" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    } else {
        const submoduleType = exampleIds.includes(selectedSubmoduleId) ? 'examples' : 'submodules';
        const submodulesDatabag = submoduleType === 'examples' ? exampleIds : submoduleIds;
        const submoduleTypeLabel = submoduleType === 'examples' ? 'Example of' : 'Submodule'
        const submoduleName = relationships?.submodules[selectedSubmoduleId].attributes.name;

        const mdDescription = getMdDescription(submoduleReadme || '');

        return (
            <Row className="px-3">
                <Helmet>
                    <meta
                        name="description"
                        content={`${submoduleTypeLabel} ${submoduleName} | ${mdDescription ? mdDescription + ' | ' : ''}${moduleVersionData?.data.attributes.description}` || moduleTitle}
                    />
                    <link rel="canonical" href={canonicalUrl} />
                </Helmet>
                <Col lg={12} className="rounded-pane-top bg-gradient-2">
                    <Button
                        variant="link"
                        onClick={(e) => {
                            e.preventDefault();
                            onSelectSubModuleHandler(null);
                        }}
                        className="text-decoration-none px-0"
                    >
                        &larr; Return to module {module.name}
                    </Button>
                </Col>
                <Col lg={12} className="rounded-pane-bottom pane-shadow">
                    <Row className="mx-0">
                        <Col className="d-flex justify-content-start px-0 pb-3">
                            <h3 className="text-nowrap my-auto">
                                <strong>
                                    {submoduleType === 'examples' ? 'Examples' : 'Submodules'}
                                    <span className="px-1">/</span>
                                </strong>
                            </h3>
                            <Form.Select
                                aria-label="Submodule"
                                className="w-auto"
                                defaultValue={selectedSubmoduleId}
                                onChange={(e) => {
                                    e.preventDefault();
                                    onSelectSubModuleHandler(Number(e.target.value));
                                }}
                            >
                                {submodulesDatabag &&
                                    submodulesDatabag.map(function (id) {
                                        if (relationships) {
                                            if (relationships.submodules[id]) {
                                                return (
                                                    <option key={`submodules-form-selector-${moduleVersionId}-${id}`} value={id}>
                                                        {relationships.submodules[id].attributes.name}
                                                    </option>
                                                );
                                            }
                                        }
                                        return '';
                                    })}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <span className="text-secondary">Source code:</span>{' '}
                            <a
                                href={`${module.source}/tree/${moduleVersionData?.data.attributes.tag}/${
                                    relationships?.submodules[selectedSubmoduleId].attributes.path
                                }`}
                                className="text-decoration-none"
                            >
                                <GoLink />
                                {module.source.replace(/^[^\/]+\/\/[^\/]+\//, '')}/tree/{moduleVersionData?.data.attributes.tag}/
                                {relationships?.submodules[selectedSubmoduleId].attributes.path}
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
};
