import { MouseEvent } from 'react';
import { DocSubcategory, ProviderCardPropsType, ProviderDocumentationStateType } from './types';
import { recursiveToCamel } from '../common';
import { getCategoryName, getSubcategoryName } from './index';

export const adjustResourceName = (name: string, providerName: string) => {
    const prefixRegexp = new RegExp('^' + providerName.replace(/[\W_]/g, '').replace(/(.)/g, '$1_?'));

    return prefixRegexp.exec(name) ? name : providerName + '_' + name;
};

export const wordWrapByUnderscores = (str: string) => {
    return str.replace(/_/g, '_\u200B');
};

export const isModifiedEvent = (e: MouseEvent<Element>) => (
    e.defaultPrevented || e.metaKey || e.ctrlKey || e.shiftKey || e.altKey || e.button === 1
);

type MarkdownMetaDataType = {
    title? : string;
    description?: string;
}

const trimQuotes = (text: string) => (
    text
        .replace(/^"(.+)"$/,'$1')
        .replace(/^'(.+)'$/,'$1')
);

export const extractMetadataFromMarkdown = (markdown: string): MarkdownMetaDataType => {
    const charactersBetweenGroupedHyphens = /^---([\s\S]*?)---/;
    const metadataMatched = markdown.match(charactersBetweenGroupedHyphens);
    const metadata = metadataMatched && metadataMatched.length == 2 && metadataMatched[1];

    if (!metadata) {
        return {};
    }

    const metadataLines = metadata.replace(/\|-[\n\r]+/, '').split("\n");

    const metadataObject = metadataLines.reduce<Record<string, string>>((accumulator, line) => {
        const [key, ...value] = line.split(":").map((part) => part.trim());

        if (key)
            accumulator[key] = trimQuotes(value[1] ? value.join(":") : value.join(""));
        return accumulator;
    }, {});

    return {
        title: metadataObject.page_title,
        description: metadataObject.description,
    };
};

const regex = {
    title: /^#\s+.+/,
    heading: /^#+\s+.+/,
    custom: /\$\$\s*\w+/,
    ol: /\d+\.\s+.*/,
    ul: /\*\s+.*/,
    task: /\*\s+\[.]\s+.*/,
    blockQuote: />.*/,
    table: /\|.*/,
    image: /!\[.+]\(.+\).*/,
    url: /\[.+]\(.+\).*/,
    codeBlock: /`{3}\w+.*/,
};

const stripLinks = (text: string) => text.replaceAll(/\[(.*?)]\(.*?\)/g, '$1');
const stripBoldAndItalic = (text: string) => text.replaceAll(/[*_]/g, '');

const isTitle = (str: string) => regex.title.test(str);
const isHeading = (str: string) => regex.heading.test(str);
const isCustom = (str: string) => regex.custom.test(str);
const isOl = (str: string) => regex.ol.test(str);
const isUl = (str: string) => regex.ul.test(str);
const isTask = (str: string) => regex.task.test(str);
const isBlockQuote = (str: string) => regex.blockQuote.test(str);
const isImage = (str: string) => regex.image.test(str);
const isCodeBlock = (str: string) => regex.codeBlock.test(str);

export function getMdTitle(md: string) {
    if (!md) return '';

    const tokens = md.split('\n');

    for (const item of tokens) {
        if (isTitle(item)) return item;
    }
    return '';
}

export function getMdDescription(markdown: string) {
    if (!markdown) return '';

    const tokens = markdown.replaceAll(/\r/g, '').split('\n\n');

    for (const item of tokens) {
        if (
            item.trim() === '' ||
            isHeading(item) ||
            isCustom(item) ||
            isOl(item) ||
            isUl(item) ||
            isTask(item) ||
            isBlockQuote(item) ||
            isImage(item) ||
            isCodeBlock(item)
        )
            continue;

        return stripBoldAndItalic(stripLinks(item));
    }
    return '';
}

export const transformProvidersData = (response: any) => {
    const result: ProviderCardPropsType[] = [];
    response = recursiveToCamel(response);
    const included: any = {
        categories: {},
        'provider-versions': {},
        'source-participation': {}
    };
    for (const d of response.included) {
        included[d.type][d.id] = d;
    }
    for (const d of response.data) {
        const item: ProviderCardPropsType = {
            provider: {
                id: d.id,
                type: d.type,
                attributes: d.attributes
            },
            categories: d.relationships.categories.data.map((e: any) => included.categories[e.id]),
            latestVersion: included['provider-versions'][d.relationships.latestVersion.data.id],
            sourceParticipation: !!d.relationships.sourceParticipation.data
                ? included['source-participation'][d.relationships.sourceParticipation.data.id]
                : undefined
        };
        result.push(item);
    }
    return result;
};

export const transformProviderVersionResponse = (r: any, category?: string, title?: string): ProviderDocumentationStateType | null => {
    let target: any;
    const items: any = {};
    const guides: any = {};
    const result: any = {
        guides: [],
        items: [],
        selected: null,
        selectedCat: null,
        selectedSub: null
    };

    // Provider docs are sorted on the backend.

    for (const d of r.included) {
        if (d.type === 'provider-docs') {
            const pd = recursiveToCamel(d);

            if (pd.attributes.slug === 'index') {
                // Root node is always on the top of the tree.
                result.root = pd;

                if (category === undefined || title === undefined) {
                    result.selected = pd;
                }

                continue;
            }

            // Guides should be on the top of the categories.
            target = pd.attributes.category === 'guides' ? guides : items;

            const cat = getCategoryName(pd);
            const sub = getSubcategoryName(pd);

            if (!(cat in target)) {
                target[cat] = {};
            }

            if (!(sub in target[cat])) {
                target[cat][sub] = [];
            }

            target[cat][sub].push(pd);

            if (category !== undefined && title !== undefined && !result.selected) {
                if (category === pd.attributes.category && title === pd.attributes.slug) {
                    result.selected = pd;
                    result.selectedCat = cat;
                    result.selectedSub = sub;
                }
            }
        }
    }

    const t: any = {
        guides: guides,
        items: items
    };

    Object.keys(t).forEach((tg) => {
        Object.keys(t[tg]).forEach((cat) => {
            const catEl: DocSubcategory = {
                title: cat,
                items: []
            };
            Object.keys(t[tg][cat]).forEach((sub) => {
                catEl.items.push({
                    title: sub,
                    items: t[tg][cat][sub]
                });
            });
            result[tg].push(catEl);
        });
    });

    if (!!result.root && !result.selected) {

        return null;
    }

    return result;
};
