import React, { useEffect, useState } from 'react';
import { RouteProps } from 'react-router';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Alert, Col, Image, Row, Tab, Tabs } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { BsFileText } from 'react-icons/bs';
import { GoCpu, GoLink, GoLog, GoRepoForked, GoVersions } from 'react-icons/go';
import moment from 'moment/moment';
import { Helmet } from 'react-helmet';

import API from '../../api';
import { toHumanString } from '../common';
import {
    recursiveToCamel,
    DownloadsSummary,
    SourceInfo,
    SourceTopLabels,
    ConfigurationInstructions,
    VersionList,
    Graph
} from '../';
import MarkdownChangelog from './markdownChangelog';
import { defaultTitle } from '../constants';
import {
    ProviderDocumentationResourceType,
    ProviderDocumentationStateType,
    ProviderState,
    ProviderTabTypes,
    ProviderVersionItemType
} from './types';
import Overview from './overview';
import { ForksList } from './forkList';
import { transformProviderVersionResponse } from './utils';
import { getCategoryName, getSubcategoryName } from './index';
import { PlatformList } from './platformList';
import { ProviderVersionPlatformType } from '../resourceTypes';
import { Favorite } from '../Favorite/favorite';
import { FAVORITE_PROVIDERS_LIST_ID } from '../Favorite/useFavorite';
import { Icon } from '../Icon/Icon';
import { Badges } from '../Badges/badges';

const getConfigurationInstructions = (providerName: string, providerFullName: string, version: string) => (
    `terraform {
  required_providers {
    ${providerName} = {
      source = "${providerFullName}"
      version = "${version}"
    }
  }
}

provider ${providerName} {
  # Configuration options
}`
);

const isOfTypeProviderTab = (key: string): key is ProviderTabTypes => {
    return ['overview', 'changelog', 'versions', 'forks', 'platforms'].includes(key);
};

export const ProviderPage: React.FC<RouteProps> = () => {
    const { namespace, name, version, category, title } = useParams();
    const location = useLocation();
    const [state, setState] = useState<ProviderState>();
    const [providerVersion, setProviderVersion] = useState<ProviderVersionItemType>();
    const [providerVersionPlatforms, setProviderVersionPlatforms] = useState<ProviderVersionPlatformType[] | undefined>();

    const [providerDocsTree, setProviderDocsTree] = useState<ProviderDocumentationStateType | null>(null);
    const [providerDocumentation, setProviderDocumentation] = useState('');

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const qTab = searchParams.get('tab');

    const currentTab = !!qTab && isOfTypeProviderTab(qTab) ? qTab : 'overview';

    const scrollIntoView = () => {
        if (!location.hash) {
            return;
        }
        const node = document.querySelector(`[id='${location.hash.substring(1)}']`);
        if (node) {
            node.scrollIntoView();
        }
    };

    const configurationInstructions = state
        ? getConfigurationInstructions(
            state.provider.attributes.name,
            state.provider.attributes.fullName,
            state.latestVersion.attributes.version,
        )
        : '';

    const getFullRelation = (apiResponse: any, relation: any, nameOfRelation: string) => {
        const result = relation.relationships[nameOfRelation]
            && relation.relationships[nameOfRelation].data
            && relation.relationships[nameOfRelation].data.id
            ? apiResponse.included.find(
                (includedResource: any) => (
                    includedResource.type === relation.relationships[nameOfRelation].data.type)
                        && includedResource.id === relation.relationships[nameOfRelation].data.id
            )
            : undefined;

        return result ? recursiveToCamel(result) : undefined;
    };

    const transformProviderResponse = (r: any) => {
        const included: any = {
            categories: {},
            'provider-versions': {},
            'source-info': {},
            'source-changelog': {},
            'source-participation': {},
            'source-top-labels': {},
            'provider-downloads': {},
            providers: {},
        };

        for (const d of r.included) {
            if (included[d.type] !== undefined) {
                included[d.type][d.id] = recursiveToCamel(d);
            }
        }
        const get = (x: string, rel?: string) => {
            if (!rel) {
                rel = x;
            }

            return !!r.data.relationships[x].data && r.data.relationships[x].data.id in included[rel]
                ? included[rel][r.data.relationships[x].data.id]
                : undefined;
        };

        const result: ProviderState = {
            providerResponse: r,
            provider: {
                id: r.data.id,
                type: r.data.type,
                attributes: recursiveToCamel(r.data.attributes)
            },
            categories: r.data.relationships.categories.data.map((e: any) => included.categories[e.id]),
            providerVersions: r.data.relationships['provider-versions'].data.map((e: any) => included['provider-versions'][e.id]),
            latestVersion: get('latest-version', 'provider-versions'),
            sourceInfo: get('source-info'),
            sourceParticipation: get('source-participation'),
            sourceChangelog: get('source-changelog'),
            sourceTopLabels: get('source-top-labels'),
            providerDownloads: get('provider-downloads'),
            potentialForkOf: get('potential-fork-of', 'providers')
        };

        return result;
    };

    const setProviderVersionWrapper = (versionNumber: string, s: ProviderState | undefined = undefined) => {
        if (!s) {
            return;
        }
        if (versionNumber === 'latest') {
            return setProviderVersion(s.latestVersion);
        }
        for (const v of s.providerVersions) {
            if (v.attributes.version === versionNumber) {
                return setProviderVersion(v);
            }
        }
        // There is no such version number of this provider.
        navigate('/NotFound');
    };

    useEffect(() => {
        setState(undefined);
        setProviderVersion(undefined);

        // Get the provider
        API.get('/providers/' + encodeURIComponent(namespace ?? '') + '/' + encodeURIComponent(name ?? ''))
            .then((response) => {
                const data = response.data;

                const newProviderState = transformProviderResponse(data);
                setState(newProviderState);
                setProviderVersionWrapper(version!, newProviderState);
                document.title = `${data.data.attributes['full-name']} | Providers | ${defaultTitle}`;
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                    navigate('/NotFound');
                }
            });
    }, [namespace, name]);

    useEffect(() => {
        if (state) {
            const result = getFullRelation(state.providerResponse, providerVersion, 'platforms');

            setProviderVersionPlatforms(result ? result.attributes.platforms : undefined);
        }
    }, [state, providerVersion]);

    useEffect(() => {
        const nameParam = encodeURIComponent(name ?? '');
        const namespaceParam = encodeURIComponent(namespace ?? '');
        const versionParam = encodeURIComponent(version ?? 'latest');

        // Get the version
        API.get(`/providers/${namespaceParam}/${nameParam}/versions/${versionParam}`)
            .then((response) => {
                const docsTree = transformProviderVersionResponse(response.data, category, title);

                if (docsTree) {
                    setProviderDocsTree(docsTree);
                } else {
                    navigate('/NotFound');
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                    navigate('/NotFound');
                }
            });
    }, [namespace, name, version]);


    useEffect(() => {
        const nameParam = encodeURIComponent(name ?? '');
        const namespaceParam = encodeURIComponent(namespace ?? '');
        const versionParam = encodeURIComponent(version ?? 'latest');
        const categoryParam = encodeURIComponent(category ?? 'overview');
        const titleParam = encodeURIComponent(title ?? 'index');

        // Get the version
        API.get(`/providers/${namespaceParam}/${nameParam}/versions/${versionParam}/docs/${categoryParam}/${titleParam}`)
            .then((response) => {
                const doc = response.data;

                setProviderDocumentation(doc.data.attributes.content);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [namespace, name, version, category, title]);

    const setVersion = (ver: string) => {
        setProviderVersionWrapper(ver, state);
        navigate(`/providers/${namespace}/${name}/${ver}`);
    };

    const onSelectTabHandler = (tab: string | null) => {
        if (!!tab && isOfTypeProviderTab(tab)) {
            setSearchParams((params) => {
                if (tab === 'overview') {
                    params.delete('tab');
                } else {
                    params.set('tab', tab);
                }
                return params;
            });
        }
    };

    const onSelectDocHandler = (doc: ProviderDocumentationResourceType) => {
        if (providerDocsTree) {
            setProviderDocsTree({
                ...providerDocsTree,
                selected: doc,
                selectedCat: getCategoryName(doc),
                selectedSub: getSubcategoryName(doc)
            });
        }
    };

    const isReady = !!state
        && state.provider.attributes.name === name
        && state.provider.attributes.namespace === namespace;


    const startPartOfUrl = `/providers/${encodeURIComponent(namespace ?? '')}/${encodeURIComponent(name ?? '')}`;
    const canonicalUrl = window.location.href.replace(`${startPartOfUrl}/${version}`, `${startPartOfUrl}/latest`);

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <IconContext.Provider value={{ className: 'react-icons' }}>
            <Row>
                <Col lg={9} className="pe-md-4">
                    {isReady && (
                        <div>
                            <Helmet>
                                <meta
                                    name="description"
                                    content={state.provider.attributes.description || state.provider.attributes.fullName}
                                />
                                <link rel="canonical" href={canonicalUrl} />
                            </Helmet>
                            <Col lg={12}>
                                <Row className="rounded-pane-top bg-gradient-2 m-0">
                                    <Col lg={12}>
                                        <div className="text-secondary">
                                            <a href="/" className="breadcrumb-link">
                                                Home
                                            </a>{' '}
                                            /{' '}
                                            <a href="/providers" className="breadcrumb-link">
                                                Providers
                                            </a>
                                        </div>
                                        <Row className="align-middle">
                                            <Col>
                                                <h1>
                                                    Provider: {state.provider.attributes.name}
                                                </h1>
                                            </Col>
                                            <Col className="col-auto d-flex flex-row-reverse align-items-center px-0">
                                                <Favorite
                                                    itemId={state.provider.id}
                                                    listId={FAVORITE_PROVIDERS_LIST_ID}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="rounded-pane-bottom pane-shadow m-0">
                                    {!!state.potentialForkOf && (
                                        <Col lg={12}>
                                            <Alert variant="danger">
                                                This provider is a fork of the{' '}
                                                <Link to={`/providers/${state.potentialForkOf.attributes.fullName}/latest`} className="text-decoration-none fw-bold">
                                                    {state.potentialForkOf.attributes.fullName}
                                                </Link>{' '}
                                                provider!
                                            </Alert>
                                        </Col>
                                    )}

                                    <Row className="pe-0">
                                        <Col lg={9} md={9} sm={8}>
                                            <Row>
                                                <Col lg={12} className="pb-3">
                                                    <span className="text-secondary">Description:</span>{' '}
                                                    {state.provider.attributes.description === ''
                                                        ? state.latestVersion.attributes.description === ''
                                                            ? 'n/a'
                                                            : state.latestVersion.attributes.description
                                                        : state.provider.attributes.description}
                                                </Col>

                                                <Col lg={12} hidden={state.provider.attributes.ownerName === ''} className="pb-3">
                                                    <span className="text-secondary">Owner:</span>
                                                    <span className="fw-bold">{state.provider.attributes.ownerName}</span>
                                                </Col>

                                                <Col lg={12} className="pb-3">
                                                    <span className="text-secondary">Last published:</span>
                                                    {' '}
                                                    {moment(state.latestVersion.attributes.publishedAt, 'YYYY-MM-DDThh:mm:ssZ').fromNow()} by{' '}
                                                    <strong>{state.provider.attributes.namespace}</strong>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col lg={3} md={3} sm={4} className="pe-0">
                                            <Row>
                                                <Col lg={12} className="pb-3 pe-0 text-sm-end">
                                                    <span className="text-primary ps-sm-3" title="Weekly commits for the past 52 weeks">
                                                        {state.sourceParticipation &&
                                              <Graph height="2.3em" rawData={state.sourceParticipation.attributes.data.all} />}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Col lg={12} className="pb-3">
                                        <span className="text-secondary">Source code:</span>{' '}
                                        <a href={state.provider.attributes.source} className="text-decoration-none">
                                            <span className="text-nowrap">
                                                <GoLink />
                                                {state.provider.attributes.source.replace(/^[^\/]+\/\/[^\/]+\//, '')}
                                            </span>
                                        </a>
                                    </Col>

                                    {!!state.sourceInfo && !!state.sourceInfo.attributes.forkedFrom && (
                                        <Col lg={12} className="pb-3">
                                            <span className="text-secondary">Forked from:</span>{' '}
                                            <a href={state.sourceInfo.attributes.forkedFrom} className="text-decoration-none">
                                                <GoRepoForked />
                                                {state.sourceInfo.attributes.forkedFrom.replace(/^[^\/]+\/\/[^\/]+\//, '')}
                                            </a>
                                        </Col>
                                    )}

                                    {!!providerVersion && providerVersion.attributes.downloads > 0 && (
                                        <Col lg={12} className="pb-3">
                                            <span className="text-secondary">Version: </span>
                                            <span className="fw-bold">
                                                {providerVersion.attributes.version}
                                            </span>
                                            {` was downloaded ${toHumanString(providerVersion.attributes.downloads)} times in total.`}
                                        </Col>
                                    )}
                                    {!!providerVersion && !providerVersion.attributes.downloads && (
                                        <Col lg={12} className="pb-3">
                                            <span className="text-secondary">Version:</span>
                                            <span className="fw-bold">{providerVersion.attributes.version}</span> has
                                            never
                                            been downloaded.
                                        </Col>
                                    )}

                                    <Col lg={12}>
                                        <span className="text-secondary">This provider is available in</span>{' '}
                                        {state.provider.attributes.isInTofuRegistry && (
                                            <>
                                                <span className="px-1 fst-italic text-nowrap">
                                                    <Icon id="opentofu" className="me-1 mb-1 platformIcon" />
                                                    OpenTofu
                                                </span>
                                                {` and `}
                                            </>
                                        )}
                                        <span className="ps-1 fst-italic text-nowrap">
                                            <Icon id="terraform" className="me-1 mb-1 platformIcon" />
                                            Terraform
                                        </span>
                                        {' '}
                                        <span className="text-secondary ps-1">
                                            {state.provider.attributes.isInTofuRegistry ? 'registries.' : 'registry.'}
                                        </span>
                                    </Col>
                                    <Col lg={12}>
                                        <Badges badges={state.provider.attributes.badges} subject="provider" />
                                    </Col>
                                </Row>
                            </Col>

                            <Row>
                                <Col lg={12} className="py-4">
                                    <Tabs
                                        key="tabs"
                                        id="provider-version-tab"
                                        defaultActiveKey={currentTab}
                                        activeKey={currentTab}
                                        onSelect={onSelectTabHandler}
                                        className="rounded-pane"
                                        transition={false}
                                    >
                                        <Tab
                                            key="overview-tab"
                                            eventKey="overview"
                                            title={
                                                <span>
                                                    <BsFileText />
                                                    Documentation
                                                </span>
                                            }
                                        >
                                            {!!providerVersion && currentTab === 'overview' && (
                                                <Overview
                                                    key={`overview-${providerVersion.id}`}
                                                    providerVersion={providerVersion}
                                                    provider={state.provider}
                                                    namespace={namespace}
                                                    name={name}
                                                    version={version}
                                                    providerDocsTree={providerDocsTree}
                                                    providerDocumentation={providerDocumentation}
                                                    onComplete={scrollIntoView}
                                                    onSelectDoc={onSelectDocHandler}
                                                />
                                            )}
                                        </Tab>

                                        {!!state.sourceChangelog && (
                                            <Tab
                                                key="changelog-tab"
                                                eventKey="changelog"
                                                title={
                                                    <span>
                                                        <GoLog />
                                                        Changelog
                                                    </span>
                                                }
                                            >
                                                {currentTab === 'changelog' && (
                                                    <MarkdownChangelog
                                                        key={`md-changelog-${state.provider.id}`}
                                                        id={state?.sourceChangelog?.id}
                                                        changelog={state?.sourceChangelog?.attributes.changelog}
                                                    />
                                                )}
                                            </Tab>
                                        )}

                                        <Tab
                                            key="versions-tab"
                                            eventKey="versions"
                                            title={
                                                <span>
                                                    <GoVersions />
                                                    Versions ({state.providerVersions.length})
                                                </span>
                                            }
                                        >
                                            {currentTab === 'versions' && (
                                                <VersionList key={`version-list-${state.provider.id}`} versions={state.providerVersions} onClick={setVersion} limit={29} />
                                            )}
                                        </Tab>

                                        {state.provider.attributes.forks > 0 && (
                                            <Tab
                                                key="forks-tab"
                                                eventKey="forks"
                                                title={
                                                    <span>
                                                        <GoRepoForked />
                                                        Forks ({state.provider.attributes.forks})
                                                    </span>
                                                }
                                            >
                                                {currentTab === 'forks' && (
                                                    <ForksList providerId={state.provider.id} />
                                                )}
                                            </Tab>
                                        )}

                                        {providerVersion
                                            && providerVersionPlatforms
                                            && providerVersionPlatforms.length > 0 && (
                                            <Tab
                                                key="platforms-tab"
                                                eventKey="platforms"
                                                title={
                                                    <span>
                                                        <GoCpu />
                                                        Platforms ({providerVersionPlatforms.length})
                                                    </span>
                                                }
                                            >
                                                {currentTab === 'platforms' && (
                                                    <PlatformList platforms={providerVersionPlatforms} />
                                                )}
                                            </Tab>
                                        )}
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>

                <Col lg={3} className="pe-4">
                    {isReady && (
                        <Row className="rounded-pane pane-shadow px-4 pb-2 mb-4">
                            <Col lg={12} className="pb-2">
                                <h5 className="fw-bold">How to use this provider</h5>
                            </Col>
                            <Col lg={12} className="p-0">
                                <ConfigurationInstructions
                                    tofuInfo={state.provider.attributes.isInTofuRegistry ? (
                                        <>
                                      To install this provider, copy and paste this code into your OpenTofu
                                      configuration. Then, run <code>tofu init</code>.
                                        </>
                                    ) : undefined}
                                    terraformInfo={
                                        <>
                                        To install this provider, copy and paste this code into your Terraform
                                        configuration. Then, run <code>terraform init</code>.
                                        </>
                                    }
                                >
                                    {configurationInstructions}
                                </ConfigurationInstructions>
                            </Col>
                        </Row>
                    )}

                    {isReady && !!state.providerDownloads && (
                        <Row className="rounded-pane pane-shadow px-4 mb-4">
                            <Col lg={12} className="pb-2">
                                <h5 className="fw-bold">Provider downloads</h5>
                            </Col>
                            <DownloadsSummary
                                week={state.providerDownloads.attributes.week}
                                month={state.providerDownloads.attributes.month}
                                year={state.providerDownloads.attributes.year}
                                total={state.providerDownloads.attributes.total}
                            />
                        </Row>
                    )}

                    {isReady && !!state.sourceInfo && (
                        <Row className="rounded-pane pane-shadow px-4 mb-4">
                            <Col lg={12} className="pb-2">
                                <h5 className="fw-bold">Repository</h5>
                            </Col>
                            <SourceInfo data={state.sourceInfo} sourceURL={state.provider.attributes.source} />
                        </Row>
                    )}

                    {isReady && !!state.sourceTopLabels && (
                        <Row className="rounded-pane pane-shadow px-4 mb-4">
                            <Col lg={12} className="pb-2">
                                <h5 className="fw-bold">Top Labels</h5>
                            </Col>
                            <SourceTopLabels data={state.sourceTopLabels} sourceURL={state.provider.attributes.source} />
                        </Row>
                    )}
                </Col>
            </Row>
        </IconContext.Provider>
    );
};

export default ProviderPage;
