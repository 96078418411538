import React, { FC } from 'react';
import { BadgeType } from './types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BadgeIcon } from './badgeIcon';

import './styles.scss';

type BadgePropsType = {
    readonly badge: BadgeType,
}

export const Badge: FC<BadgePropsType> = ({ badge }) => (
    <OverlayTrigger
        flip
        placement="top"
        overlay={(
            <Tooltip id="tooltip">
                {badge.description}
            </Tooltip>
        )}
    >
        <div className={`one-badge ${badge.icon}`}>
            <div>
                <BadgeIcon badge={badge} />
            </div>
            <div>
                {badge.title}
            </div>
        </div>
    </OverlayTrigger>
);
