import React, { FC } from 'react';
import { IconContext } from 'react-icons';
import { Col, Row } from 'react-bootstrap';
import { BsClock } from 'react-icons/bs';
import { GoDownload } from 'react-icons/go';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { PiUser } from 'react-icons/pi';

import { Graph } from '../index';
import { ProviderCardPropsType } from './types';
import { toHumanString } from '../common';
import { MdFavoriteBorder } from 'react-icons/md';
import { Favorite } from '../Favorite/favorite';
import { FAVORITE_PROVIDERS_LIST_ID } from '../Favorite/useFavorite';
import { Icon } from '../Icon/Icon';
import { Badges } from '../Badges/badges';

export const ProviderCard: FC<ProviderCardPropsType> = ({
    provider,
    latestVersion,
    sourceParticipation,
}) => {
    const attr = provider.attributes;
    const versionAttr = latestVersion.attributes;

    return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
        <IconContext.Provider value={{ className: 'react-icons' }}>
            <Link to={`/providers/${attr.fullName}/latest`} key={`provider-${provider.id}`} className="provider-card rounded-pane-middle">
                <Row className="mb-2">
                    <Col className="entity-card-header h4">
                        {attr.namespace} / {attr.name}
                    </Col>
                    <Col className="col-auto">
                        <Favorite itemId={provider.id} listId={FAVORITE_PROVIDERS_LIST_ID}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-muted">{!!attr.description ? attr.description : versionAttr.description}</Col>
                </Row>
                <Row className="mt-3 text-secondary">
                    <Col title="Last publish">
                        <BsClock />
                        {moment(versionAttr.publishedAt, 'YYYY-MM-DDThh:mm:ssZ').fromNow()}
                    </Col>
                    <Col title="Downloads total">
                        <GoDownload />
                        {toHumanString(attr.downloads)}
                    </Col>
                    <Col title="Weekly commits for the past 52 weeks">
                        <div className="mt-n1">{!!sourceParticipation && <Graph height="1.3em" rawData={sourceParticipation.attributes.data.all} />}</div>
                    </Col>
                    <Col title="The provider owner">
                        <PiUser />
                        <span>{attr.ownerName}</span>
                    </Col>
                    <Col title={`This provider is available in ${attr.isInTofuRegistry ? 'OpenTofu and Terraform registries.' : 'Terraform registry.'}`}>
                        <span className="nowrap">
                            <span className="hide-less-lg">Available in: </span>
                            {attr.isInTofuRegistry && (
                                <>
                                    <Icon id="opentofu" className="me-1 mb-1 platformIcon" />
                                    {` / `}
                                </>
                            )}
                            <Icon id="terraform" className="me-1 mb-1 platformIcon" />
                        </span>
                    </Col>
                </Row>
            </Link>
        </IconContext.Provider>
    );
};
