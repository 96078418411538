import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Card, Image } from 'react-bootstrap';

import { Navigation, NotFoundPage, ModulePage, ModulesListPage, ProviderPage, ProvidersListPage, Dashboard, Insights, AboutPage } from './components';
import './App.scss';
import { isDarkTheme } from './components/common';
import { FavoriteContainer } from './components/Favorite/favoriteContainer';

const App: React.FC = () => {
    return (
        <>
            <Container fluid className="App">
                <Navigation />
                <FavoriteContainer>
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/insights" element={<Insights />} />
                        <Route path="/modules" element={<ModulesListPage />} />
                        <Route path="/providers" element={<ProvidersListPage />} />
                        <Route path="/providers/:namespace/:name/:version" element={<ProviderPage />} />
                        <Route path="/providers/:namespace/:name/:version/docs/:category/:title" element={<ProviderPage />} />
                        <Route path="/modules/:namespace/:name/:provider/:version" element={<ModulePage />} />
                        <Route path="/modules/:namespace/:name/:provider/:version/:target/:submoduleName" element={<ModulePage />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </FavoriteContainer>
            </Container>
            <footer className="text-center">
                <Card className="border-0">
                    <Card.Body>
                        <div className="p-2">
                            <p className="text-muted">
                                <span className="me-2">Powered by</span>
                                <Link to="https://scalr.com" target="_blank" rel="noopener">
                                    <Image
                                        height={12}
                                        src={`/images/scalr-full-logo${isDarkTheme() ? '-dark' : ''}.svg`}
                                        className="mt-n1"
                                        alt="https://scalr.com"
                                        title="Click to open www.scalr.com."
                                        style={{ opacity: '90%' }}
                                    />
                                </Link>
                            </p>
                            <p className="small text-secondary">
                                Notice of Non-Affiliation and Disclaimer: We are not affiliated, associated, authorized, endorsed by, or in any way officially
                                connected with HashiCorp, or any of its subsidiaries or its affiliates.
                            </p>
                        </div>
                    </Card.Body>
                </Card>
            </footer>
        </>
    );
};

export default App;
