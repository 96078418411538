import React, { useState } from 'react';
import { Nav, Navbar, ToggleButton } from 'react-bootstrap';
import { MdContrast, MdOutlineDarkMode, MdOutlineLightMode } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { GlobalSearch } from '../index';
import { setSiteTheme } from '../../redux/actions';
import { isDarkTheme } from '../common';
import { useLocation } from 'react-router-dom';
import { IconContext } from 'react-icons';

const Navigation: React.FC = () => {
    const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem('theme') ?? 'auto');
    const dispatch = useDispatch();
    const location = useLocation();

    const toggleTheme = (e: any) => {
        e.preventDefault();

        let theme = selectedTheme;

        if (theme === 'auto') {
            theme = 'dark';
        } else if (theme === 'dark') {
            theme = 'light';
        } else {
            theme = 'auto';
        }

        localStorage.setItem('theme', theme);

        if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.documentElement.setAttribute('data-bs-theme', 'dark');
        } else {
            document.documentElement.setAttribute('data-bs-theme', theme);
        }

        setSelectedTheme(theme);
        dispatch(setSiteTheme({ theme: theme }));
    };

    const isSelected = (pathname: string) => {
        return location.pathname === pathname ? 'selected' : '';
    };
    const commonPadding = 'mx-4 mx-md-0';

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <IconContext.Provider value={{ className: 'react-icons' }}>
            <Navbar expand="lg" className="navigation py-1">
                <Navbar.Brand href="/" className="pe-5">
                    <Nav>
                        <img
                            alt="library.tf"
                            src={`/images/brand-logo${isDarkTheme() ? '-dark' : ''}.svg`}
                            width="137"
                            height="19"
                            className="d-inline-block align-middle"
                        />
                    </Nav>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <Nav.Link className={`${isSelected('/')} ${commonPadding}`} href="/">
                            Home
                        </Nav.Link>
                        <Nav.Link className={`${isSelected('/providers')} ${commonPadding}`} href="/providers">
                            Providers
                        </Nav.Link>
                        <Nav.Link className={`${isSelected('/modules')} ${commonPadding}`} href="/modules">
                            Modules
                        </Nav.Link>
                        <Nav.Link className={`${isSelected('/insights')} ${commonPadding}`} href="/insights">
                            Insights
                        </Nav.Link>
                        <Nav.Link className={`${isSelected('/about')} ${commonPadding}`} href="/about">
                            About
                        </Nav.Link>
                        <Nav.Link className={commonPadding} href="https://forms.gle/1eZhCvAQBJtVQ2UA8" target="_blank" rel="noopener">
                            Feedback
                        </Nav.Link>
                    </Nav>
                    <Nav className={`me-auto${!isSelected('/') ? ' global-search px-3' : ''}`}>{!isSelected('/') &&
                        <GlobalSearch />}
                    </Nav>
                    <Nav className="toggle-theme">
                        <ToggleButton
                            variant="theme"
                            id="bd-theme"
                            type="checkbox"
                            aria-label="Toggle theme"
                            value={selectedTheme}
                            onClick={toggleTheme}
                            title="Click to toggle theme."
                            className="text-start mx-2 mx-md-1"
                        >
                            {selectedTheme === 'auto' ? (
                                <span className="text-nowrap">
                                    <MdContrast />
                                    Auto
                                </span>
                            ) : (
                                ``
                            )}
                            {selectedTheme === 'dark' ? (
                                <span className="text-nowrap">
                                    <MdOutlineDarkMode />
                                    Dark
                                </span>
                            ) : (
                                ``
                            )}
                            {selectedTheme === 'light' ? (
                                <span className="text-nowrap">
                                    <MdOutlineLightMode />
                                    Light
                                </span>
                            ) : (
                                ``
                            )}
                        </ToggleButton>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </IconContext.Provider>
    );
};

export default Navigation;
