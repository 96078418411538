import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import API from '../../api';
import { CategoryResourceType, TierResourceType } from '.';
import { explodeList } from '../';
import { FaCircleCheck } from 'react-icons/fa6';
import {isModifiedEvent} from "./utils";
import {Helmet} from 'react-helmet';
import {defaultTitle, providerDescription} from '../constants';
import { BadgesFilter } from '../Badges/badgesFilter';
import { FavoriteCount } from '../Favorite/favoriteCount';
import { FavoriteContext } from '../Favorite/favoriteContext';
import { FAVORITE_PROVIDERS_LIST_ID } from '../Favorite/useFavorite';

export const tiers: TierResourceType[] = [
    { name: 'Official', slug: 'official' },
    { name: 'Partner', slug: 'partner' },
    { name: 'Community', slug: 'community' }
];

export const ProviderListFilter = () => {
    const [filterParams, setFilterParams] = useSearchParams();
    const [categories, setCategories] = useState<CategoryResourceType[]>([]);

    const { getFavoriteItems } = useContext(FavoriteContext);

    const favoriteItems = getFavoriteItems(FAVORITE_PROVIDERS_LIST_ID);

    const category = explodeList(filterParams.get('category'));
    const tier = explodeList(filterParams.get('tier'));
    const selectedBadgeTypes = explodeList(filterParams.get('badges'));
    const showFavoriteOnly = filterParams.get('favorite') === '1';

    const onFavoriteClickHandler = () => {
        setFilterParams((params) => {
            if (showFavoriteOnly) {
                params.delete('favorite');
            } else {
                params.set('favorite', '1');
            }

            return params;
        });
    }

    const setFilter = (slug: string, target: string[], target_name: string, size: number) => {
        const index = target.indexOf(slug, 0);
        if (index > -1) {
            target.splice(index, 1);
        } else {
            target.push(slug);
        }
        if (target.length === size) {
            target.splice(0, target.length);
        }
        setFilterParams((params) => {
            if (target.length === 0) {
                params.delete(target_name);
            } else {
                params.set(target_name, target.join(','));
            }
            return params;
        });
    };

    useEffect(() => {
        API.get('/categories')
            .then((response) => {
                const r = response.data;
                setCategories(r.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const categoryNames = useMemo(() => (
        categories
            .filter((element) => category.includes(element.attributes.slug))
            .map((element) => element.attributes.name)
    ), [categories, category]);

    const resetFilter = (e: React.MouseEvent) => {
        e.preventDefault();
        setFilterParams((params) => {
            params.delete('category');
            params.delete('tier');

            return params;
        });
    };

    const hasFilter = () => category.length > 0 || tier.length > 0;

    const onChangeBadgeFilterHandler = (badgeTypes: string[]) => {
        setFilterParams((params) => {
            if (badgeTypes.length === 0) {
                params.delete('badges');
            } else {
                params.set('badges', badgeTypes.join(','));
            }
            return params;
        });
    }

    return (
        <Row>
            <Row className="mb-2">
                <FavoriteCount
                    count={favoriteItems.length}
                    showFavoriteOnly={showFavoriteOnly}
                    onClick={onFavoriteClickHandler}
                />
            </Row>

            <Row className="mb-2">
                <BadgesFilter
                    subject="provider"
                    selectedBadgeTypes={selectedBadgeTypes}
                    onChange={onChangeBadgeFilterHandler}
                />
            </Row>

            <Row>
                <div className="filter-head">
                    <div className="text-secondary">
                        By category
                    </div>
                    <div>
                        {hasFilter() && (
                            <Button variant="link" onClick={resetFilter} className="btn border-0 text-decoration-none">
                                Clear
                            </Button>
                        )}
                    </div>
                </div>
            </Row>
            <Helmet>
                <title>
                    {`${categoryNames.length ? categoryNames.join(' , ') + ' providers' : 'Providers'} | ${defaultTitle}`}
                </title>
                <meta
                    name="description"
                    content={`${categoryNames.length ? categoryNames.join(' | ') + ' | ' : ''}${providerDescription}`}
                />
            </Helmet>
            <Row>
                <Col className="pb-4">
                    <ListGroup className="pointer filter-body">
                        {categories.map(function (cat) {
                            if (cat.attributes.slug !== 'hashicorp') {
                                return (
                                    <ListGroup.Item
                                        key={`category-filter-${cat.id}`}
                                        action
                                        href={`/providers?category=${cat.attributes.slug}`}
                                        onClick={(e) => {
                                            if (!isModifiedEvent(e)) {
                                                setFilter(cat.attributes.slug, category, 'category', categories.length);
                                                e.preventDefault();
                                            }
                                        }}
                                        active={category.includes(cat.attributes.slug)}
                                        className="py-1 d-flex justify-content-between"
                                    >
                                        <div className="py-2">{cat.attributes.name}</div>
                                        {category.includes(cat.attributes.slug) && (
                                            <span className="check text-end">
                                                <FaCircleCheck />
                                            </span>
                                        )}
                                    </ListGroup.Item>
                                );
                            }
                        })}
                    </ListGroup>
                </Col>
            </Row>
            <Row hidden>
                <Col className="text-secondary my-auto ps-4 pb-4">By Tier</Col>
            </Row>
            <Row hidden>
                <Col className="pb-4">
                    <ListGroup className="pointer">
                        {tiers.map(function (t) {
                            return (
                                <ListGroup.Item
                                    key={`tier-filter-${t.slug}`}
                                    action
                                    onClick={() => setFilter(t.slug, tier, 'tier', tiers.length)}
                                    active={tier.includes(t.slug)}
                                    className="py-1 d-flex justify-content-between"
                                >
                                    <div className="py-2">{t.name}</div>
                                    {tier.includes(t.slug) && (
                                        <span className="check text-end">
                                            <FaCircleCheck />
                                        </span>
                                    )}
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </Col>
            </Row>
        </Row>
    );
};

export default ProviderListFilter;
